import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../../components/Heading';
import { Text } from '../../../components/Text';
import { Link } from '../../../components/Link';

const Container = styled.div`
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  margin: 10px auto;
  padding: 10px 20px;
  border-radius: ${p => p.theme.borderRadius};
  width: 100%;
`;

interface Props {
  title: string;
  subTitle: string;
  link: string;
  linkUrl: string;
}

export const UpgradeCard = ({ title, subTitle, link, linkUrl }: Props) => {
  return (
    <Container>
      <Heading level="4" size="large" className="my10">
        {title}
      </Heading>
      <Text>{subTitle}</Text>
      <Link primary className="mt20" to={linkUrl}>
        {link}
      </Link>
    </Container>
  );
};
